<template>
  <div class="fluid" id="holidaySettingList">
    <div>
      <div class="holiday-setting-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                HARI LIBUR NASIONAL
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="holiday-setting-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div>
                    <div style="width: 120px">
                      <v-select
                        :disabled="selectedData.length === 0"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Delete' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <v-dialog v-model="dialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size:12px;margin-left:20px;"
                        @click="addDate"
                        :disabled="loading"
                      >
                        Tambah Tanggal
                      </v-btn>
                    </template>

                    <v-form
                      :disabled="loading"
                      ref="entryForm"
                      @submit.prevent="submit"
                      style="position: relative;"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Tambah Tanggal Merah
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position:relative; top:20px;"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Nama Hari Libur
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-text-field
                                  v-model="form.name"
                                  outlined
                                  :rules="nameRules"
                                />
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Tanggal
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-text-field
                                  v-model="form.date"
                                  outlined
                                  type="date"
                                  name="date"
                                  step="1"
                                  :rules="dateRules"
                                />
                              </v-col>

                              <v-col cols="12" style="padding: 0">
                                <div style="padding: 0">
                                  <v-col
                                    cols="12"
                                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                                  >
                                    <p
                                      class="text-left"
                                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                    >
                                      Potong Cuti Tahunan
                                    </p>
                                    <v-checkbox
                                      v-model="form.is_effect_leave"
                                      style=" margin:0 0 0 10px; height:25px; padding:0;"
                                    ></v-checkbox>
                                  </v-col>
                                </div>
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="close"
                            :loading="loading"
                          >
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            text
                            outlined
                            :loading="loading"
                          >
                            Simpan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                  <div class="holiday-setting-list-toolbar-1">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="cari kategori"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; width: 10px;
                        margin-left: 10px;"
                      @keyup.enter="searchEnter"
                      :disabled="loading"
                    ></v-text-field>
                    <div
                      style="margin-left:20px;display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                        @click="searchEnter"
                        :disabled="loading"
                      >
                        search
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="holiday-setting-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="60vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.is_effect_leave`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.is_effect_leave === 1 ? 'Yes' : 'No' }}
                  </div>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.date) }}
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.created_at) }}
                  </div>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.updated_at) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'holidaysetting',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      company_id: 0,
      dept_id: '',
      itemsPerPage: 10
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Nama Hari Libur',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tanggal',
        value: 'date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Potong Cuti Tahunan',
        value: 'is_effect_leave',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Buat',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Update',
        value: 'updated_at',
        align: 'left',
        sortable: false
      }
      // {
      //   text: 'Dibuat Oleh',
      //   value: 'created_by_name',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      id: '',
      name: '',
      date: '',
      is_effect_leave: false
    },
    nameRules: [],
    dateRules: []
  }),

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company.id
    await this.getDataFromApi()
    this.setAction()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    close() {
      this.nameRules = []
      this.dateRules = []
      this.form = {
        act: 'add',
        id: '',
        name: '',
        date: '',
        is_effect_leave: false
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          this.delete()
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      return await new Promise(resolve => {
        axios
          .get(
            `${this.hrsApi}publicholiday/list?keyword=${
              this.paramAPI.keyword
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }`
          )
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addDate() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    rowClick(pItem) {
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.name = pItem.name
      this.form.date = pItem.date
      this.form.is_effect_leave = pItem.is_effect_leave === 1
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}publicholiday/delete/${this.selectedData[0].id}`
            )
            .then(async res => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    submit() {
      this.nameRules = [v => !!v || 'Name is required']
      this.dateRules = [v => !!v || 'Date is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.name,
        date: this.form.date,
        is_effect_leave: this.form.is_effect_leave ? 1 : 0
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}publicholiday/save`, form)
        .then(async res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('succes', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    }
  }
}
</script>
<style lang="scss">
#holidaySettingList {
  position: relative;
  .holiday-setting-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .holiday-setting-list-toolbar-1 {
      .holiday-setting-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .holiday-setting-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #holidaySettingList {
    .holiday-setting-list-div {
      .holiday-setting-list-toolbar-1 {
        .holiday-setting-list-toolbar-1 {
          display: none;
        }
      }
      .holiday-setting-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
